import React from 'react';
import { RichTextField } from '@prismicio/types';
import {
  SectionContent,
  SectionImage,
  SectionTextContainer,
  StyledImage,
} from '@components/security-section/security-section.styled';
import Text from '@components/text';

interface SecuritySectionProps {
  asset: {
    url: string;
  };
  asset_placement: 'left' | 'right';
  section_title: {
    text: string;
  };
  section_description: {
    richText: RichTextField;
  };
}

const SecuritySection = ({
  asset,
  asset_placement,
  section_title,
  section_description,
}: SecuritySectionProps): JSX.Element | null => {
  return (
    <SectionContent assetLeft={asset_placement !== 'right'}>
      {Boolean(asset?.url) && (
        <SectionImage>
          <StyledImage url={asset?.url} />
        </SectionImage>
      )}
      <SectionTextContainer $center={!asset?.url}>
        {Boolean(section_title) && <Text type="title" stringText={section_title?.text} />}
        {Boolean(section_description) && (
          <Text type="subheading" richText={section_description?.richText} />
        )}
      </SectionTextContainer>
    </SectionContent>
  );
};

export default SecuritySection;
