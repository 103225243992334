import React from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/seo';
import Layout from '@layouts/default-layout';
import { RichTextField } from '@prismicio/types';
import Wrapper from '@components/wrapper.styled';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import HubspotForm from '@components/hubspot-form';
import SecuritySection from '@components/security-section';
import PageHero from '@components/page-hero';
import { Section } from '@components/security-section/security-section.styled';
import useScrollToSection from '@hooks/use-scroll-to-section';

interface SecuritySection {
  primary: {
    asset: {
      url: string;
    };
    asset_placement: 'left' | 'right';
    section_title: {
      text: string;
    };
    section_description: {
      richText: RichTextField;
    };
  };
}

interface SecurityInterface {
  data: {
    prismicGeneralConfig: {
      data: {
        favicon?: {
          url?: string;
        };
      };
    };
    prismicSecurity: {
      data: {
        page_title: {
          text: string;
        };
        title: {
          text: string;
        };
        description: {
          richText: RichTextField;
        };
        button_text: string;
        form_title: {
          text: string;
        };
        form_description: {
          richText: string;
        };
        body: SecuritySection[];
      };
    };
  };
}

const SecurityPage = ({ data }: SecurityInterface): JSX.Element | null => {
  if (!data) return null;

  useScrollToSection('request');

  const {
    prismicGeneralConfig: {
      data: { favicon },
    },
    prismicSecurity: {
      data: {
        page_title,
        title,
        description,
        button_text,
        form_title,
        form_description,
        body: sections,
      },
    },
  } = data;

  return (
    <Layout isAlternativeFooterProvided>
      <SEO title={page_title.text} faviconHref={favicon?.url || ''} />
      <PageHero
        title={title?.text}
        subheading={description?.richText}
        imageLayout="inline"
        linkLabel={button_text}
        linkUrl={{ url: '/security?section=request' }}
        centerAll
        backgroundColor="white"
      />
      <Wrapper>
        {sections.map((s) => (
          <Section>
            <SecuritySection {...s?.primary} />
          </Section>
        ))}
        <HubspotForm
          id="request"
          title={form_title?.text}
          description={form_description?.richText}
          hubspotFormId={process.env.GATSBY_HUBSPOT_REQUEST_SECURITY_WHITEPAPER_FORM_ID ?? ''}
          layout="vertical"
        />
      </Wrapper>
    </Layout>
  );
};

export default withPrismicPreview(SecurityPage);

export const query = graphql`
  query SecurityPageQuery {
    prismicGeneralConfig {
      ...GeneralConfigFragment
    }
    prismicSecurity {
      data {
        body {
          ... on PrismicSecurityDataBodySection {
            slice_label
            primary {
              section_title {
                text
              }
              section_description {
                richText
              }
              asset {
                url
              }
              asset_placement
            }
          }
        }
        title {
          text
        }
        page_title {
          text
        }
        description {
          richText
        }
        button_text
        form_title {
          text
        }
        form_description {
          richText
        }
      }
    }
  }
`;
