import styled from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import Image from '@components/image';

export const Section = styled.div`
  ${generateScales('margin-bottom', '3rem', '10rem')}
`;

export const SectionContent = styled.div<{ assetLeft: boolean }>`
  ${({ theme, assetLeft }) => `
    display: flex;
    align-items: center;
    flex-direction: ${assetLeft ? 'row' : 'row-reverse'};
    
    gap: 2rem;

    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
    }
  `}
`;

export const SectionImage = styled.div`
  max-height: 20rem;
  max-width: 60%;
  flex-grow: 1;
  flex-shrink: 0;
`;

export const StyledImage = styled(Image)`
  max-height: 20rem;
  max-width: 100%;
`;

export const SectionTextContainer = styled.div<{ $center: boolean }>`
  ${({ $center }) => `
    text-align: ${$center ? 'center' : 'left'};
    flex-grow: 1;
  `}
`;
